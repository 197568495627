import { VariantProps, cva } from "class-variance-authority";
import { cn } from "utils/utils";

const homeArticleVariants = cva("relative flex flex-col justify-center gap-4", {
  variants: {
    color: {
      transparent: "text-midnightblue",
      white: "bg-white text-midnightblue",
      teal: "bg-teal text-midnightblue",
      midnightblue: "bg-midnightblue text-white",
      turquoise: "bg-turquoise text-midnightblue",
      "light-turquoise": "bg-light-turquoise text-midnightblue",
      "extra-light-turquoise": "bg-extra-light-turquoise text-midnightblue",
      iron: "bg-iron text-midnightblue",
      "light-iron": "bg-light-iron text-midnightblue",
    },

    verticalPadding: {
      default: "py-3 lg:py-5",
      light: "py-2 lg:py-4",
      none: "",
      clint: "py-6 lg:py-[3.75rem]",
    },
    horizontalPadding: {
      default: "px-4 lg:px-12",
      light: "px-2 lg:px-5",
      none: "",
      desktopOnly: "px-0 lg:px-12",
    },
    maxWidth: {
      full: "w-full max-w-full",
      "1280": "w-full lg:max-w-[1280px] max-w-full mx-auto rounded-[20px]",
      "1440": "w-full max-w-[1280px] mx-auto",
    },
  },

  defaultVariants: {
    color: "transparent",
    // spacing: "primary",
    verticalPadding: "none",
    horizontalPadding: "none",
    maxWidth: "1440",
  },
});

export const HomeArticle = ({
  className,
  children,
  color,
  verticalPadding,
  horizontalPadding,
  maxWidth,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement> &
  VariantProps<typeof homeArticleVariants>) => {
  return (
    <article
      {...props}
      className={cn(
        homeArticleVariants({
          color,
          maxWidth,
          horizontalPadding,
          verticalPadding,
        }),
        className,
      )}
    >
      {children}
    </article>
  );
};
